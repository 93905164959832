<template>
  <v-container fluid class="px-lg-5">
    <popup
      v-if="enable"
      @popupClosed="handlechange"
      :kudo="selectedKudo"/>
    <table-filter
      @filterTable="filterTable"
      :items="kudosOptions"
      :label="$t('models.user.attributes.personalKudos')">
    </table-filter>
    <v-row align="center" justify="center">
      <div class="col-12">
        <v-card class="rounded-card" flat outlined>
          <v-data-table
            class="selectCursor"
            :headers="headers"
            :items="personalKudos.items"
            :options.sync="options"
            @update:options="updateOptions"
            :server-items-length="personalKudos.count"
            :loading="loading" flat
            :no-data-text="$t('messages.emptyState', { entity: $tc('models.kudo.entity') })"
            :no-results-text="$t('messages.emptyState', { entity: $tc('models.kudo.entity') })"
            @click:row="handleClick">
            <template v-if="this.givenKudo && (this.isAdmin || this.currentUser)" v-slot:[`item.actions`]="{ item }">
              <v-btn icon small @click="updateLeaveRequest(item)">
                <v-icon>mdi-pen</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-row>
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab bottom right fixed class="mr-5" v-on="on"
            v-bind="attrs" @click="newKudoRequest">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('layout.accountNavMenu.requestNewKudos') }} </span>
      </v-tooltip>
    </v-fab-transition>
  </v-container>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import popup from '../../../../components/kudos/popup';
export default {
  data() {
    return {
      selectedKudo: null,
      enable: false,
      givenKudo: true,
      headers: [
        {
          text: '',
          value: 'id',
          align: ' d-none'
        },
        {
          text: this.$t('models.kudo.attributes.title'),
          value: 'title'
        },
        {
          text: this.$t('models.kudo.attributes.description'),
          value: 'description'
        },
        {
          text: this.$t('models.kudo.attributes.badge'),
          value: 'badge'
        },
        {
          text: this.$t('models.kudo.attributes.creator_name'),
          value: 'creator_name'
        },
        {
          text: this.$t('models.kudo.attributes.assignee_name'),
          value: 'assignee_name'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '120px'
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['title'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      loading: false
    };
  },
  components: {
    popup
  },
  methods: {
    ...mapActions([
      'getPersonalKudos',
      'destroyKudo'
    ]),
    open(item) {
      this.selectedKudo = item;
      this.enable = true;
    },
    updateLeaveRequest(kudo) {
      this.$router.push({
        name: 'EditKudoRequest',
        params: {id: kudo.id}
      });
    },
    newKudoRequest() {
      this.$router.push({name: 'Kudos'});
    },
    updateOptions(options) {
      options = {
        ...options,
        uuid: this.$route.params.uuid,
        givenKudo: this.givenKudo
      };
      this.loading = true;
      this.getPersonalKudos(options).then(() => (this.loading = false));
    },
    filterTable(personalKudoOption) {
      this.givenKudo = personalKudoOption === 'Given Kudos' ? true : false;
      this.updateOptions(this.options);
    },
    handleClick(kudo) {
      this.selectedKudo = kudo;
      this.enable = true;
    },
    handlechange() {
      this.enable = false;
    },
    deleteKudo(kudo) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: kudo.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyKudo(kudo.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      'personalKudos',
      'isAdmin',
      'currentUser'
    ]),
    kudosOptions() {
      return ['Given Kudos', 'Received Kudos'];
    }
  },
  created() {
    let options = this.options;
    options = {
      ...options,
      uuid: this.$route.params.uuid,
      givenKudo: this.givenKudo
    };
    this.getPersonalKudos(options);
  }
};
</script>
<style>
  .selectCursor{
    cursor: pointer;
  }
</style>
