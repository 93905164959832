<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 primary mb-5">
          {{this.kudo.title}}
        </v-card-title>

        <v-card-text>
          This kudo was given by {{ this.kudo.creator_name }} to {{ this.kudo.assignee_name }}
        </v-card-text>
        <v-card-text>
          Details: {{ this.kudo.description }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closePopup()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    kudo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    closePopup() {
      this.dialog = false;
      this.$emit('popupClosed', this.dialog);
    }
  }
};
</script>
